import React, { Suspense, useRef, useEffect } from "react";
import { Canvas, useFrame, useLoader } from "@react-three/fiber";
import { Ground } from "./Ground";

import {
  OrbitControls,
  PerspectiveCamera,
  ScrollControls,
  useScroll,
  useGLTF,
  Scroll,
} from "@react-three/drei";
import "./style.css";

function CarShow() {
  return (
    <>
      {/* <OrbitControls target={[0, 0.35, 0]} maxPolarAngle={1.45} /> */}

      {/* <PerspectiveCamera makeDefault fov={50} position={[3, 2, 5]} /> */}

      <color args={[0, 0, 0]} attach="background" />

      <spotLight
        color={[1, 0.25, 0.7]}
        intensity={1.5}
        angle={0.6}
        penumbra={0.5}
        position={[5, 5, 0]}
        castShadow
        shadow-bias={-0.0001}
      />
      <spotLight
        color={[0.14, 0.5, 1]}
        intensity={2}
        angle={0.6}
        penumbra={0.5}
        position={[-5, 5, 0]}
        castShadow
        shadow-bias={-0.0001}
      />

      <ScrollControls
        pages={3} // Each page takes 100% of the height of the canvas
        distance={1} // A factor that increases scroll bar travel (default: 1)
        damping={4} // Friction, higher is faster (default: 4)
        horizontal={false} // Can also scroll horizontally (default: false)
        infinite={false} // Can also scroll infinitely (default: false)
      >
        <LittlestTokyo/>
      </ScrollControls>
    </>
  );
}
function LittlestTokyo() {
  const scroll = useScroll();
  useFrame((state, delta) => {
    // The offset is between 0 and 1, you can apply it to your models any way you like
    console.log(scroll.offset);
    const offset = scroll.offset;

    // zi = (xi – min(x)) / (max(x) – min(x))
    // where:
    // zi: The ith normalized value in the dataset
    // xi: The ith value in the dataset
    // min(x): The minimum value in the dataset
    // max(x): The maximum value in the dataset

    //http://learnwebgl.brown37.net/07_cameras/points_along_a_path.html
    // p = (1-t)*p1 + t*p2;  
    // where t varies from 0.0 to 1.0 , p1 = point 1, p2 = point 2

    if (offset < 0.5) {
      var zi = (offset - Math.min(0)) / (Math.max(0.5) - Math.min(0));
      var pz = (1 - zi) * 5 + zi * 10;
      var vz = (1 - zi) * 0 + zi * 2;
      state.camera.position.set(1, 2, pz);
      state.camera.lookAt(0, 0, 0);
    }
    if (offset >= 0.5) {
      var zi = (offset - Math.min(0.5)) / (Math.max(1) - Math.min(0.5));
      var pz = (1 - zi) * 10 + zi * 20;
      var px = (1 - zi) * 1 + zi * 50;
      state.camera.position.set(px, 2, pz);
      state.camera.lookAt(0, 0, 0);
    }
  });
  return <Ground />;
}

function App() {
  return (
    <Suspense fallback={null}>
      <Canvas shadows>
        <CarShow />
      </Canvas>
    </Suspense>
  );
}
export default App;
