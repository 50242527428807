import React, { useEffect } from "react";
import { useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { Mesh } from "three";

export function Cloth() {
  //using draco loader with gltf loader. Gave error "Uncaught SyntaxError: Unexpected token '<' "
  //solved by changing the path as in stackoverflow"
  // https://stackoverflow.com/questions/56071764/how-to-use-dracoloader-with-gltfloader-in-reactjs
  // https://threejs.org/docs/#examples/en/loaders/GLTFLoader
  // https://www.tabnine.com/code/javascript/functions/react-three-fiber/useLoader

  const gltf = useLoader(
    GLTFLoader,
    process.env.PUBLIC_URL + "Models/fullClothNew.gltf",
    (loader) => {
      const dracoLoader = new DRACOLoader();
      dracoLoader.setDecoderPath("https://www.gstatic.com/draco/v1/decoders/");
      dracoLoader.setDecoderConfig({ type: "js" });
      loader.setDRACOLoader(dracoLoader);
    }
  );

  useEffect(() => {
    gltf.scene.scale.set(0.1, 0.1, 0.1);
    gltf.scene.position.set(0, -0.035, 0);
    gltf.scene.traverse((object) => {
      //get all children in the 3d model
      if (object instanceof Mesh) {
        object.castShadow = true;
        object.receiveShadow = true;
        object.material.envMapIntensity = 20;
      }
    });
  }, [gltf]);

  return <primitive object={gltf.scene} />;
}
